/* noto-sans-regular - latin */
@font-face {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('../Static/Fonts/noto-sans-v27-latin/noto-sans-v27-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
	url('../Static/Fonts/noto-sans-v27-latin/noto-sans-v27-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../Static/Fonts/noto-sans-v27-latin/noto-sans-v27-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
	url('../Static/Fonts/noto-sans-v27-latin/noto-sans-v27-latin-regular.woff') format('woff'), /* Modern Browsers */
	url('../Static/Fonts/noto-sans-v27-latin/noto-sans-v27-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../Static/Fonts/noto-sans-v27-latin/noto-sans-v27-latin-regular.svg#NotoSans') format('svg'); /* Legacy iOS */
}
/* noto-sans-italic - latin */
@font-face {
	font-family: 'Noto Sans';
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url('../Static/Fonts/noto-sans-v27-latin/noto-sans-v27-latin-italic.eot'); /* IE9 Compat Modes */
	src: local(''),
	url('../Static/Fonts/noto-sans-v27-latin/noto-sans-v27-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../Static/Fonts/noto-sans-v27-latin/noto-sans-v27-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
	url('../Static/Fonts/noto-sans-v27-latin/noto-sans-v27-latin-italic.woff') format('woff'), /* Modern Browsers */
	url('../Static/Fonts/noto-sans-v27-latin/noto-sans-v27-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../Static/Fonts/noto-sans-v27-latin/noto-sans-v27-latin-italic.svg#NotoSans') format('svg'); /* Legacy iOS */
}
/* noto-sans-700 - latin */
@font-face {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('../Static/Fonts/noto-sans-v27-latin/noto-sans-v27-latin-700.eot'); /* IE9 Compat Modes */
	src: local(''),
	url('../Static/Fonts/noto-sans-v27-latin/noto-sans-v27-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../Static/Fonts/noto-sans-v27-latin/noto-sans-v27-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
	url('../Static/Fonts/noto-sans-v27-latin/noto-sans-v27-latin-700.woff') format('woff'), /* Modern Browsers */
	url('../Static/Fonts/noto-sans-v27-latin/noto-sans-v27-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../Static/Fonts/noto-sans-v27-latin/noto-sans-v27-latin-700.svg#NotoSans') format('svg'); /* Legacy iOS */
}
/* noto-sans-700italic - latin */
@font-face {
	font-family: 'Noto Sans';
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url('../Static/Fonts/noto-sans-v27-latin/noto-sans-v27-latin-700italic.eot'); /* IE9 Compat Modes */
	src: local(''),
	url('../Static/Fonts/noto-sans-v27-latin/noto-sans-v27-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../Static/Fonts/noto-sans-v27-latin/noto-sans-v27-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
	url('../Static/Fonts/noto-sans-v27-latin/noto-sans-v27-latin-700italic.woff') format('woff'), /* Modern Browsers */
	url('../Static/Fonts/noto-sans-v27-latin/noto-sans-v27-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../Static/Fonts/noto-sans-v27-latin/noto-sans-v27-latin-700italic.svg#NotoSans') format('svg'); /* Legacy iOS */
}
@font-face {
	font-family: 'FontAwesome';
	src: url('../Static/Fonts/fontawesome-webfont.eot?v=4.0.3');
	src: url('../Static/Fonts/fontawesome-webfont.eot?#iefix&v=4.0.3') format('embedded-opentype'), url('../Static/Fonts/fontawesome-webfont.woff?v=4.0.3') format('woff'), url('../Static/Fonts/fontawesome-webfont.ttf?v=4.0.3') format('truetype'), url('../Static/Fonts/fontawesome-webfont.svg?v=4.0.3#fontawesomeregular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
/* ====================================================================================================================
 * FONT-FACE Flexslider
 * ====================================================================================================================*/
@font-face {
	font-family: 'flexslider-icon';
	src: url('../Static/Fonts/flex-slider/flexslider-icon.eot');
	src: url('../Static/Fonts/flex-slider/flexslider-icon.eot?#iefix') format('embedded-opentype'), url('../Static/Fonts/flex-slider/flexslider-icon.woff') format('woff'), url('../Static/Fonts/flex-slider/flexslider-icon.ttf') format('truetype'), url('../Static/Fonts/flex-slider/flexslider-icon.svg#flexslider-icon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
